.pagination {
  .page-item {
    padding: 0 1px;

    &:first-child.disabled,
    &:last-child.disabled {
      opacity: 0.5;
    }

    .page-link {
      font-size: $fs-13;
      color: var(--text-color);
      background-color: transparent;
      outline: none;
      box-shadow: none;
      border: none;
      border-radius: 4px;
      padding: 3px 9px;
    }

    &.active,
    &:not(.disabled):hover {
      .page-link {
        color: var(--white-color);
        background-color: var(--primary-color);
      }
    }
  }
}
