.notification-header {
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 15px;
}

.notification-body {
    padding: 10px 15px;

    .notification-details {
        font-size: 14px;
        width: 92%;
    }
}

.notification-close-btn {
    color: white;
}

.notifications-danger {
    background: #ff4949;
}

.notifications-warning {
    background: #f6af33;
}

.notifications-info {
    background: #4976ff;
}