.breadcrumb {
  align-items: center;
  margin-bottom: 27px;
  position: relative;
  z-index: 1;
  i{
    font-weight: bold;
    font-size: $fs-30;
    color: var(--primary-color);
    }
  li {
    font-size: $fs-24;
    font-weight: $fw-700;
    color: $white;
    text-transform: capitalize;
    line-height: 1.4;
    &.active {
      color: var(--heading-color);
    }
    a {
      color: var(--heading-color);
      display: block;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
  @include media-max(xl){
    margin-bottom: 20px;
  }
  @include media-max(md){
   display: none;
  }
}
