.Select_Option {
	color: black;
	font-size: 1rem;
}

.Question_link_image {
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	border: 1px solid #ced4da;
	border-radius: 0 0.375rem 0.375rem 0 !important;

	&:hover {
		background-color: var(--primary-color);
		cursor: pointer;
		color: whitesmoke;
	}

	button {
		border-radius: 0;
		border: none;

		svg path {
			fill: var(--white-color) !important;
		}

		&:hover {
			background-color: var(--primary-color) !important;
		}
	}
}

.question {
	position: relative;

	.img-preview {
		img {
			width: 150px;
			object-fit: cover;
			height: 150px;
		}
	}
}

.question_plus {
	position: absolute;
	right: 0;
	top: 0;

	&:hover {
		background-color: var(--primary-color);
		cursor: pointer;
	}
}

.question_Delete {
	position: absolute;
	right: 0;
	top: 0;

	&:hover {
		background-color: var(--primary-color);
		cursor: pointer;
	}
}

.question_lable {
	color: black;
	margin-right: 5px;
}

.addQuestionHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.leftarrow {
		cursor: pointer;
	}

	.Randomise_options {
		display: flex;
		flex-direction: column;
		align-items: center;

		Label {
			color: var(--primary-color);
			font-weight: bold;
		}
	}
}

.Updatebtn {
	display: flex;
	justify-content: space-between;
}

.form-switch .form-check-input:checked {
	background-color: var(--primary-color) !important;
}
