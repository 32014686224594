.main-layout {
	height: 100vh;

	// padding-left: 56px;
	.main-container {
		// height: calc(100% - 66px);
		// overflow-y: auto;
		padding: 90px 5px 20px 70px;
		transition: padding 0.5s;

		&.active {
			padding-inline-start: 260px;
		}
	}

	@include media-max(md) {
		.main-container {
			padding: 90px 4px 10px 60px;
		}
	}
}

.modal-content .modal-header {
	border: none;
}

.modal-content .modal-footer {
	border: none;
	justify-content: center;
}

.modal-footer {
	padding: 0;
}

.question {
	.img-preview {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row-reverse;
		margin-top: 25px;

		button {
			position: absolute;
			top: -7px;
			right: -7px;
			padding: 3px 10px;
		}
	}

	.wrapper {
		padding: 15px;
	}
}

.wrapper {
	padding: 15px;
}

.correct-answer-header {
	label {
		// margin-right: 10px;
	}
}

.add-question-form {
	> .wrapper {
		padding: 15px;
		background: transparent;
		box-shadow: none;

		.form-switch .form-check-input {
			margin-left: 15px !important;
			background-color: #dbe0e2;
		}
	}
}

.in-active-box {
	border-radius: 8px;
	box-shadow: 1px 1px 7px #ea9898, -1px -1px 7px #ea9898;
}

.active-box {
	border-radius: 8px;
	box-shadow: 1px 1px 7px #58ff20, -1px -1px 7px #ea9898;
}

.option-wrapper {
	padding-top: 35px;
	border-top: 1px dashed rgb(128, 128, 128);
	overflow-x: auto;
	flex-wrap: nowrap;
}

.option-wrapper::-webkit-scrollbar {
	height: 7px;
}

.custom-collaps {
	transition: 0.3s;
	max-height: 0;
	overflow: hidden;
	padding: 0px 10px;
}

.custom-collaps.active-collaps {
	max-height: 500px;
	transition: 0.3s;
}

.answer-box {
	margin-top: 15px;
}

.tooglebtn {
	margin-left: 15px;
	padding: 5px;
	background: transparent;
	background-color: transparent !important;
	border-color: transparent !important;
	transform: rotate(180deg);
	transition: 0.3s;

	img {
		max-width: 25px;
		max-height: 25px;
	}

	&:focus {
		border: none !important;
		outline: none !important;
	}

	&:hover {
		border: none !important;
		outline: none !important;
	}
}

.active-btn.tooglebtn {
	transform: rotate(0deg);
	transition: 0.3s;
}

.tox-statusbar {
	display: none !important;
}

.subject-dropdown {
	.select__menu {
		position: absolute;
	}
}

.questions-header {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	> div {
		margin-right: 20px;
		margin-bottom: 0;

		label {
			color: #000 !important;
		}
	}
}

.student-search.search-box {
	// width: 285px;
	width: 100%;
	height: 40px;
	border: 1px solid #dfe4ec;
	border-radius: 8px;
	display: flex;
	position: relative;

	.search_icon {
		cursor: pointer;
		padding: 0;
		margin: 0;
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		right: 8px;
	}

	input {
		border: none;
		box-shadow: none;
		height: 100%;
		padding-left: 30px;
		margin: 0;
		font-size: $fs-14;
		color: var(--heading-color);
		border: 1px solid transparent;

		&::placeholder {
			color: var(--gray-100);
		}

		&:focus {
			color: var(--heading-color);
			box-shadow: none;
			border-color: var(--primary-color);
		}
	}
}

.square-icon {
	svg {
		path {
			fill: #fff;
		}
	}

	&:hover {
		svg {
			path {
				fill: var(--primary-color);
			}
		}
	}
}

.que-square-icon {
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		max-width: 0;
		overflow: hidden;
		display: inline-block;
		transition: 0.3s;
	}

	// &:hover {
	//   span {
	//     max-width: 200px;
	//     padding-left: 15px;
	//     transition: 0.3s;
	//   }
	// }
}

.create-question-button span {
	max-width: unset;
	overflow: unset;
	margin-right: 5px;
}

@media (max-width: 767px) {
	.question {
		.input-group {
			margin-bottom: 20px;
		}
	}
}

.passwordLink {
	font-size: 0.85rem;
	color: #000;
	cursor: pointer;
	margin-left: 10px;
	font-weight: 500;

	&:hover {
		color: var(--primary-color);
		text-decoration: underline;
	}
}
