.dropdown-menu {
  background-color: var(--white-color);
  border-radius: 8px;
  min-width: 205px;
  padding: 10px 0;
  box-shadow: 0px 0px 10px rgba(178, 191, 210, 0.25);

  &:before {
    content: "";
    position: absolute;
  }

  &.up-arrow {
    margin-top: 20px;
    padding: 0;
    overflow: hidden;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:before {
      display: none;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--secondary-700);
      bottom: 100%;
      right: 15px;
    }
  }

  &.left-arrow {
    margin-top: 10px;

    &:before {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid var(--secondary-700);
      right: 100%;
      top: 15px;
    }
  }

  .dropdown-item {
    color: var(--text-color);
    background-color: transparent;
    font-size: $fs-14;
    padding: 13px 20px;
    font-weight: 500;

    i {
      font-size: $fs-20;
      vertical-align: -2px;
      margin-right: 9px;
    }

    &:hover {
      color: var(--white-color);
      background-color: var(--primary-color);
    }
  }

  &.big {
    .dropdown-item {
      font-size: $fs-16;
    }
  }
}