// ! Colors

:root {
  --primary-color: #ff5555;
  --white-color: #ffffff;
  --text-color:#445774;
  --theme-color: #D1D9E0;
  --light-black-color:#333333;
  --input-color:#FFFFFF33;
  --heading-color:#1C2430;
  --secondary-100: #f2f4f7;
  --gray-100: #b2bfd2;
  --error-color:#ff0000;
}